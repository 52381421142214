.body {
    padding: var(--space-16);
    background-color: var(--color-background-main);
}

.body.dark {
    background-color: var(--color-text-main);
    color: var(--color-background-main);
}

@media (min-width: 768px) {
    .body {
        padding: var(--space-24);
    }
}
